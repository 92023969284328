import React from 'react'
import CookieBanner from 'react-cookie-banner'

import '../assets/scss/main.scss'

import Header from './Header'

class Template extends React.Component {
  render() {
    const { children } = this.props

    return (
      <>
        <div>
          <Header />
          {children}
        </div>
        <CookieBanner
          message="Privacy & Cookies: This site uses cookies. By continuing to use this website, you agree to their use."
          onAccept={() => {}}
          dismissOnScrollThreshold={100}
          styles={{
            banner: {
              position: 'fixed',
              bottom: '0',
            },
          }}
        />
      </>
    )
  }
}

export default Template
