import React from 'react'

import Footer from './Footer'
import avatar from '../assets/images/avatar.jpg'

class Header extends React.Component {
  render() {
    return (
      <header id="header">
        <div className="inner">
          <a href="/" className="image avatar">
            <img src={avatar} alt="" />
          </a>
          <h1>
            <strong>I am Duncan</strong>, I make things for the web and
            sometimes write about it at{' '}
            <a href="https://duncanbrown.dev">duncanbrown.dev</a>.
          </h1>
        </div>
        <Footer />
      </header>
    )
  }
}

export default Header
